@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import-normalize;
:root {
  font-size: clamp(12px,1.2vw,1em);
  --fs-800:2.25rem;
  --fs-700:2.1rem;
  --fs-600:2rem;
  --fs-500:1.25rem;
  --fs-400:1rem;
  --spacer:.3em;
}
@media (max-width: 50em) {
  :root {
    font-size: clamp(9px,1.5vw,1em);
    --fs-800:2.75rem;
    --fs-700:2.5rem;
    --fs-600:2rem;
    --fs-500:1.75rem;
    --fs-400:1.25rem;
    --spacer:.3em;
  }
}

#root {
  display: flow-root;
}

html {
  width: 100%;
}
html::-webkit-scrollbar {
  display: fixed;
  width: 0.5rem;
}
html::-webkit-scrollbar-track {
  background-color: white;
}
html::-webkit-scrollbar-thumb {
  background-color:  #573697 ;
  border: none;
}
@media (prefers-color-scheme: dark) {
  html::-webkit-scrollbar-thumb {
    background-color:  #573697 ;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0px auto;
}

* {
  padding: 0px;
  font-family: "Montserrat";
  margin: 0;
  font-size: var(--fs-400);
}

p {
  margin-bottom: var(--spacer);
}

h1 {
  font-size: var(--fs-800);
  margin-bottom: var(--spacer);
}

h2 {
  font-size: var(--fs-700);
  margin-bottom: var(--spacer);
}

h3 {
  font-size: var(--fs-500);
  font-weight: bold;
  margin-bottom: var(--spacer);
}

h4 {
  font-size: var(--fs-500);
  margin-bottom: var(--spacer);
}

html {
  scroll-behavior: smooth;
}

button {
  border: none;
  background: none;
  color: white;
}

a, button {
  cursor: pointer;
}

section {
  width: stretch;
  padding: 40px 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.stripes {
  width: stretch;
  display: block;
}

.stripped-card {
  background-color:  #ffa34b ;
  background: linear-gradient(10deg, #ffaa00, #ff6a00);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}
@media (prefers-color-scheme: dark) {
  .stripped-card {
    background-color:  #ffa34b ;
  }
}
.stripped-card h1, .stripped-card h2 {
  text-align: center;
}
.stripped-card > *:not(.stripes, section) {
  padding: 0 var(--fs-600);
  margin-block: var(--fs-500);
}
.stripped-card > section {
  margin-inline: var(--fs-600);
}

.secondary-button {
  background-color: transparent;
  display: block;
  border-radius: 10000px;
  border: solid 2px white;
  color: white;
  padding: 0.5em 1em;
}

.action-button {
  background: linear-gradient(10deg, #ffaa00, #ff6a00);
  color:  white ;
  font-size: var(--fs-400);
  font-weight: 700;
  padding: 0.5em 1em;
  border-radius: 10000px;
  text-transform: capitalize;
}
@media (prefers-color-scheme: dark) {
  .action-button {
    color:  white ;
  }
}

.backdrop {
  all: initial;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  width: fill;
  height: 100vh;
  background-color:  rgba(87, 54, 151, 0.8) ;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (prefers-color-scheme: dark) {
  .backdrop {
    background-color:  rgba(87, 54, 151, 0.8) ;
  }
}

.modal {
  width: clamp(50%, 700px, 90%);
  background: linear-gradient(10deg, #ffaa00, #ff6a00);
  color: white;
  margin: auto;
  padding: 1rem 2rem;
  border-radius: var(--fs-400);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}
.modal .head {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal .close-button {
  width: fit-content;
  position: absolute;
  margin: 0;
  padding: 0;
  right: 1rem;
  top: 1rem;
  background: none;
}
.modal .close-button svg {
  width: 2rem;
}
.modal .close-button svg #background {
  fill: black;
}

.contact {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.contact a {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
}
.contact a * {
  font-size: var(--fs-600);
  color: initial;
}
.contact a * + * {
  margin-left: 0.5rem;
}
.contact a + a {
  margin-top: 1rem;
}

.navbar {
  position: fixed;
  z-index: 1000;
  width: stretch;
  left: 0;
  top: 0;
  background-color:  #573697 ;
  color: white;
  backdrop-filter: blur(2px);
  padding: 0px 1em;
  margin-bottom: 0px;
  max-height: 100vh;
}
@media (prefers-color-scheme: dark) {
  .navbar {
    background-color:  #573697 ;
  }
}
.navbar .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: stretch;
  padding: 0.5em;
  padding-bottom: 0;
  max-width: 1080px;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 50em) {
  .navbar .container {
    flex-wrap: wrap;
  }
}
.navbar .container::after {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  background-color:  #ffa34b ;
  height: 0.2em;
  width: stretch;
}
@media (prefers-color-scheme: dark) {
  .navbar .container::after {
    background-color:  #ffa34b ;
  }
}
.navbar .container .brand {
  margin-bottom: 0.6em;
  display: flex;
  color: inherit;
  text-decoration: none;
}
.navbar .container .brand .logo #logo #background {
  fill:  #573697 ;
}
@media (prefers-color-scheme: dark) {
  .navbar .container .brand .logo #logo #background {
    fill:  #573697 ;
  }
}
.navbar .container .brand .logo #logo #Fire {
  fill:  white ;
}
@media (prefers-color-scheme: dark) {
  .navbar .container .brand .logo #logo #Fire {
    fill:  white ;
  }
}
.navbar .container .brand .logo #logo #border {
  fill:  white ;
}
@media (prefers-color-scheme: dark) {
  .navbar .container .brand .logo #logo #border {
    fill:  white ;
  }
}
.navbar .container .brand .logo-name {
  display: none;
  flex-direction: column;
  margin-left: 20px;
}
@media (min-width: 37.5em) {
  .navbar .container .brand .logo-name {
    display: inline-flex;
    margin: auto;
    margin-left: 0.5rem;
  }
}
.navbar .container .brand .logo-name .main-text {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}
.navbar .container .brand .logo-name .sub-text {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1em;
}
.navbar .container .brand:hover .logo > #logo #background, .navbar .container .brand:focus .logo > #logo #background {
  transition: ease-in-out all 0.5s;
}
.navbar .container .brand:hover .logo > #logo #border, .navbar .container .brand:focus .logo > #logo #border {
  transition: ease-in-out all 0.5s;
}
.navbar .container .brand:hover .logo > #logo #Fire, .navbar .container .brand:focus .logo > #logo #Fire {
  transition: ease-in-out all 0.5s;
  animation: fire 1s infinite;
  animation-direction: alternate;
  fill:  white ;
}
@keyframes fire {
  0% {
    fill:  white ;
    @media (prefers-color-scheme: dark) {
      .navbar .container .brand:hover .logo > #logo #Fire, .navbar .container .brand:focus .logo > #logo #Fire {
        fill:  white ;
      }
    }
  }
  100% {
    fill:  #ffa34b ;
    @media (prefers-color-scheme: dark) {
      .navbar .container .brand:hover .logo > #logo #Fire, .navbar .container .brand:focus .logo > #logo #Fire {
        fill:  #ffa34b ;
      }
    }
  }
}
@media (prefers-color-scheme: dark) {
  .navbar .container .brand:hover .logo > #logo #Fire, .navbar .container .brand:focus .logo > #logo #Fire {
    fill:  white ;
  }
}
.navbar .container .collapse-btn {
  background: transparent;
}
.navbar .container .collapse-btn svg > * {
  stroke: white;
  stroke-width: 3px;
}
.navbar .container .collapse-btn:hover svg > * {
  animation: pulse infinite 2s;
  animation-direction: alternate-reverse;
}
@keyframes pulse {
  100% {
    stroke:  #ffa34b ;
    @media (prefers-color-scheme: dark) {
      .navbar .container .collapse-btn:hover svg > * {
        stroke:  #ffa34b ;
      }
    }
    stroke-width: 5px;
  }
}
@media (min-width: 50em) {
  .navbar .container .collapse-btn {
    display: none;
  }
}
.navbar .container .menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 24em;
}
@media (max-width: 50em) {
  .navbar .container .menu {
    transition: ease-in-out all 0.5s;
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    font-size: 1em;
    flex-direction: column;
    height: stretch;
    overflow: hidden;
    max-height: 0;
    padding: 0 0.5rem;
  }
  .navbar .container .menu.show {
    transition: ease-in-out max-height 1s;
    max-height: 100vh;
  }
}
.navbar .container .menu .menu-links {
  font-weight: 500;
  display: flex;
}
@media (max-width: 50em) {
  .navbar .container .menu .menu-links {
    width: stretch;
    flex-direction: column;
  }
  .navbar .container .menu .menu-links div {
    margin: 0 1.5rem;
    width: stretch;
  }
}
.navbar .container .menu .menu-links div {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.5s;
  position: relative;
}
@media (min-width: 50em) {
  .navbar .container .menu .menu-links div {
    height: stretch;
  }
  .navbar .container .menu .menu-links div::after {
    position: absolute;
    content: "";
    display: block;
    bottom: 0%;
    height: 0.5em;
    width: stretch;
    background-color:  #ffa34b ;
    transition: all ease-in-out 0.5s;
    transform: scaleX(0%);
  }
}
@media (min-width: 50em) and (prefers-color-scheme: dark) {
  .navbar .container .menu .menu-links div::after {
    background-color:  #ffa34b ;
  }
}
@media (min-width: 50em) {
  .navbar .container .menu .menu-links div.active::after {
    transition: all ease-in-out 0.5s;
    transform: scaleX(150%);
  }
}
@media (max-width: 50em) {
  .navbar .container .menu .menu-links div {
    border-radius: 1000px;
  }
  .navbar .container .menu .menu-links div a {
    padding: 1em;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .navbar .container .menu .menu-links div.active {
    border: 1px solid;
    border-color:  #ffa34b ;
  }
}
@media (max-width: 50em) and (prefers-color-scheme: dark) {
  .navbar .container .menu .menu-links div.active {
    border-color:  #ffa34b ;
  }
}
.navbar .container .menu .menu-links div a {
  position: relative;
  text-decoration: none;
  color: inherit;
}
@media (min-width: 50em) {
  .navbar .container .menu .menu-links div {
    margin-right: 2em;
  }
}
.navbar .container .menu .menu-links:last-child {
  margin: 0;
}
.navbar .container .menu button.action-button {
  margin: auto 0;
  height: fit-content;
  text-wrap: nowrap;
}
@media (max-width: 50em) {
  .navbar .container .menu button.action-button {
    margin: 3rem;
    padding: 2rem;
    width: stretch;
  }
}

.loading .wrapper svg {
  width: stretch;
  height: stretch;
  max-width: 50px;
  max-height: 50px;
  animation: circle 4s infinite;
}

.hero {
  position: relative;
  width: stretch;
  overflow: hidden;
  height: clamp(300px,30vw,70vh);
  height: clamp(300px,50vw,500px);
}
@media (max-width: 50em) {
  .hero {
    height: clamp(300px,50vw,500px);
  }
}
.hero .foreground {
  color:  #573697 ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  position: absolute;
  width: stretch;
  height: stretch;
  inset: 0;
}
@media (min-width: 50em) {
  .hero .foreground {
    position: relative;
    top: -2%;
  }
}
.hero .foreground h1 {
  font-size: var(--fs-800);
  font-style: normal;
  font-weight: bold;
}
.hero .foreground h2 {
  font-size: var(--fs-500);
}
@media (prefers-color-scheme: dark) {
  .hero .foreground {
    color:  #573697 ;
  }
}

.hero.home {
  margin-top: 60px;
  background: radial-gradient(97.05% 97.05% at 50% 2.95%, rgba(87, 54, 151, 0.47) 19.45%, rgba(96, 60, 147, 0.28) 47.76%, rgba(255, 163, 75, 0.15) 77.05%, rgba(255, 163, 75, 0) 99.48%), url("../media/doctor-patient-interpreter xsm1.jpg");
  background-origin: border-box, content-box;
  background-blend-mode: multiply;
  background-position-x: center;
  background-position-y: 10%;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 130%;
}
@media (min-width: 1200px) {
  .hero.home {
    background: radial-gradient(97.05% 97.05% at 50% 2.95%, rgba(87, 54, 151, 0.47) 19.45%, rgba(96, 60, 147, 0.28) 47.76%, rgba(255, 163, 75, 0.15) 77.05%, rgba(255, 163, 75, 0) 99.48%), url("../media/doctor-patient-interpreter xsm1.jpg");
    background-origin: border-box, content-box;
    background-blend-mode: multiply;
    background-position-x: center;
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vw;
  }
}
@media (max-width: 50em) {
  .hero.home {
    background: radial-gradient(97.05% 97.05% at 50% 2.95%, rgba(87, 54, 151, 0.47) 19.45%, rgba(96, 60, 147, 0.28) 47.76%, rgba(255, 163, 75, 0.15) 77.05%, rgba(255, 163, 75, 0) 99.48%), url("../media/doctor-patient-interpreter xsm1.jpg");
    background-blend-mode: multiply;
    background-origin: border-box, content-box;
    background-position-x: center;
    background-attachment: unset;
    background-position-y: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 130%;
    color: white;
  }
}
@media (max-width: 37.5em) {
  .hero.home {
    background: radial-gradient(97.05% 97.05% at 50% 2.95%, rgba(87, 54, 151, 0.47) 19.45%, rgba(96, 60, 147, 0.28) 47.76%, rgba(255, 163, 75, 0.15) 77.05%, rgba(255, 163, 75, 0) 99.48%), url("../media/doctor-patient-interpreter xsm1.jpg");
    background-blend-mode: multiply;
    background-origin: border-box, content-box;
    background-position-x: center;
    background-attachment: unset;
    background-position-y: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 150vw;
    color: white;
  }
}
.hero.home .foreground {
  margin: 0 auto;
  padding: 15px;
}
.hero.home .foreground .content {
  display: grid;
  place-items: center;
}
.hero.home .foreground .content h1 {
  text-transform: capitalize;
}
.hero.home .foreground .content h1, .hero.home .foreground .content h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 50em) {
  .hero.home .foreground .content h1, .hero.home .foreground .content h2 {
    color: white;
    text-align: center;
    font-weight: bolder;
  }
}
.hero.home .foreground .content h2 {
  font-size: var(--fs-500);
}

.button-container {
  margin-top: 3%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 75%;
    align-content: space-around;
    align-items: center;
    height: 100%;
}
.button-container * {
  text-decoration: none;
}

.mini-about {
  background-color:  #573697 ;
  color:  white ;
  font-family: Montserrat, sans-serif;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 20px;
  column-gap: 20px;
}
@media (prefers-color-scheme: dark) {
  .mini-about {
    background-color:  #573697 ;
  }
}
@media (prefers-color-scheme: dark) {
  .mini-about {
    color:  white ;
  }
}
@media (max-width: 50em) {
  .mini-about {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
.mini-about .content {
  margin: auto;
  font-size: 0.85em;
}
.mini-about .content p {
  margin-bottom: 0.5em;
}
.mini-about .content:last-child {
  margin: 0;
}
.mini-about .profile {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.mini-about .profile .img-container {
  margin: 0 auto;
  border-radius: 10000px;
  width: 10em;
  height: 10em;
  position: relative;
  overflow: hidden;
  place-items: center;
}
.mini-about .profile .img-container img {
  height: 100%;
  width: 100%;
}
.mini-about .profile .info {
  display: flex;
  flex-direction: column;
  margin: auto 0;
}
.mini-about .profile .info * {
  margin-bottom: 0.3em;
}
.mini-about .profile .info a {
  margin: 0;
  margin-top: 1em;
  text-decoration: none;
  color: inherit;
  width: fit-content;
}

.why-choose-certified .column-layout {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}
@media (max-width: 50em) {
  .why-choose-certified .column-layout {
    display: flex;
    flex: column;
    flex-direction: column-reverse;
  }
}
.why-choose-certified .column-layout .box {
  display: grid;
  text-align: left;
}
.why-choose-certified .column-layout .box p {
  margin-bottom: 0.5em;
}
.why-choose-certified .column-layout .box h3 {
  color:  #573697 ;
  margin: 0.5em 0;
}
@media (prefers-color-scheme: dark) {
  .why-choose-certified .column-layout .box h3 {
    color:  #573697 ;
  }
}

.lets-talk {
  display: grid;
  place-items: center;
}
.lets-talk h1, .lets-talk p {
  margin-bottom: 0.3em;
}

.linkedin {
  position: relative;
  border-radius: 5px;
  border: 2px solid white;
}
.linkedin #linkedin #text {
  fill: white;
}
.linkedin #linkedin #background {
  fill: royalblue;
}

.footer {
  width: stretch;
  padding: 2em;
  background-color:  #573697 ;
  color: white;
  flex-wrap: wrap;
}
@media (prefers-color-scheme: dark) {
  .footer {
    background-color:  #573697 ;
  }
}
.footer, .footer div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.footer .logo, .footer div .logo {
  width: 40;
  height: 40;
  margin-right: 1em;
}
.footer .logo #background, .footer div .logo #background {
  fill:  #573697 ;
}
@media (prefers-color-scheme: dark) {
  .footer .logo #background, .footer div .logo #background {
    fill:  #573697 ;
  }
}
.footer .logo + div, .footer div .logo + div {
  font-size: var(--fs-600);
  line-height: var(--fs-600);
}
.footer .icons * + *, .footer div .icons * + * {
  margin-left: 1rem;
}
.footer .icons * * > *, .footer div .icons * * > * {
  fill: white;
}

.about, .services {
  margin-bottom: 1rem;
  padding-top: calc(75px + 1rem);
}
.about section, .services section {
  padding-block: 0;
  text-align: left;
  margin-bottom: 1rem;
}
.about h2, .services h2 {
  font-size: var(--fs-600);
}
.about h4, .services h4 {
  font-size: var(--fs-400);
}
.about h2, .about h4, .services h2, .services h4 {
  color:  #573697 ;
}
@media (prefers-color-scheme: dark) {
  .about h2, .about h4, .services h2, .services h4 {
    color:  #573697 ;
  }
}
.about .name, .services .name {
  border-bottom: 3px solid;
  border-color:  #573697 ;
  margin-bottom: 1em;
}
@media (prefers-color-scheme: dark) {
  .about .name, .services .name {
    border-color:  #573697 ;
  }
}
.about .description, .services .description {
  display: flex;
  flex-direction: row;
  gap: 20px;
  text-align: left;
}
@media (max-width: 50em) {
  .about .description, .services .description {
    flex-direction: column;
  }
}
.about .description .box, .services .description .box {
  margin: auto 0;
  display: grid;
  place-items: center;
  height: stretch;
}
.about .description .box p, .services .description .box p {
  padding-inline: 1rem;
  margin-bottom: 1em;
}
.about .description .box .img-container, .services .description .box .img-container {
  height: fit-content;
  margin: auto 0;
  position: relative;
  overflow: hidden;
  place-items: center;
}
@media (max-width: 50em) {
  .about .description .box .img-container, .services .description .box .img-container {
    width: 30rem;
  }
}
.about .description .box .img-container img, .services .description .box .img-container img {
  display: inline;
  width: 100%;
}
.about .description .box ul, .services .description .box ul {
  padding: 0;
}
.about .description .box ul li, .services .description .box ul li {
  display: block;
  list-style: none;
}